import { createSlice } from '@reduxjs/toolkit';
import reportService from '../services/reports.services';

const report = JSON.parse(localStorage.getItem('report'));

const initialState = {
  id: report?.id ?? null,
  startDate: report?.startDate ?? null,
  endDate: report?.endDate ?? null,
  report_summary: report?.report_summary ?? null,
  report: report?.report ?? null,
};

const reportingPeriodSlice = createSlice({
  name: 'reportingPeriod',
  initialState,
  reducers: {
    setReportingPeriod: (state, action) => {
      reportService.reportingPeriod(
        action.payload.id,
        action.payload.startDate,
        action.payload.endDate,
        action.payload.report_summary,
        action.payload.report,
        action.payload.emissions
      );

      return JSON.parse(localStorage.getItem('report'));
      //   return {
      //     id: action.payload.id,
      //     startDate: action.payload.startDate,
      //     endDate: action.payload.endDate,
      //   };
    },
    clearReportingPeriod: () => {
      reportService.removeReportingPeriod();
      return { id: null, startDate: null, endDate: null };
    },
  },
});

const { reducer, actions } = reportingPeriodSlice;

export const { setReportingPeriod, clearReportingPeriod } = actions;
export default reducer;
