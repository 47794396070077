import axios from 'axios';

const API_URL = process.env.REACT_APP_WEB_API;

const getCategory = (accessToken, adminToken) => {
  return axios.request({
    method: 'GET',
    url: `${API_URL}/api/category/utilities_elec`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
    },
  });
};

const getAllCategories = (accessToken, adminToken) => {
  return axios.request({
    method: 'GET',
    url: `${API_URL}/api/category/all`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
    },
  });
};

const categoryService = {
  getCategory,
  getAllCategories,
};

export default categoryService;
