import { configureStore } from '@reduxjs/toolkit';
import sitesReducer from './slices/sites';
import userReducer from './slices/user';
import adminCompany from './slices/adminCompany';
import company from './slices/company';
import reportingPeriod from './slices/reports';
import siteInfo from './slices/siteInfo';
import categories from './slices/categories';
import estimates from './slices/estimates';
import emissions from './slices/emissions';
import reports from './slices/allReports';

const reducer = {
  sites: sitesReducer,
  user: userReducer,
  adminCompany: adminCompany,
  company: company,
  reportingPeriod: reportingPeriod,
  reports: reports,
  siteInfo: siteInfo,
  categories: categories,
  estimates,
  emissions,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
