import axios from 'axios';
import { getAdditionalPath } from '.';

const API_URL = process.env.REACT_APP_WEB_API;

const getSitesList = (accessToken, companyId, analysisType) => {
  const ds = axios.request({
    method: 'GET',
    url: `${API_URL}/api${getAdditionalPath(analysisType)}/site/${companyId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return ds;
};

const updateSite = (accessToken, companyId, reportId, payload, analysisType) => {
  return axios.request({
    method: 'POST',
    url: `${API_URL}/api${getAdditionalPath(analysisType)}/v2/site/${companyId}/${reportId}`,
    data: payload,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const editSite = (
  accessToken,
  companyId,
  siteName,
  address,
  country,
  postalcode,
  floorspace,
  siteId
) => {
  return axios
    .request({
      method: 'PUT',
      url: `${API_URL}/api/site/${siteId}/${companyId}`,
      data: {
        company: companyId,
        site: siteName,
        address: address,
        country: country,
        postalcode: postalcode,
        floorspace: floorspace,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .catch(e => {
      return e.response;
    });
};

const deleteSite = async (accessToken, siteId) => {
  try {
    return await axios.request({
      method: 'DELETE',
      url: `${API_URL}/api/site/${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (e) {
    return e.response;
  }
};

const addressValidation = async (address, country, postalcode) => {
  try {
    return await axios.request({
      method: 'POST',
      url: `https://api.everythinglocation.com/address/complete`,
      data: {
        lqtkey: process.env.REACT_APP_LOQATE_API_KEY,
        query: address,
        country: country,
        filters: {
          PostalCode: postalcode,
        },
      },
    });
  } catch (e) {
    return e.response;
  }
};

const sitesService = {
  getSitesList,
  updateSite,
  editSite,
  deleteSite,
  addressValidation,
};

export default sitesService;
