import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import categoryService from '../services/categories.services';

export const getAllCategories = createAsyncThunk(
  'categories/getAllCategories',
  async ({ accessToken, reportingPeriod }, thunkAPI) => {
    let userToken = thunkAPI.getState().user.accountInfo.auth_info;

    try {
      if (!!userToken) {
        let response = await categoryService.getAllCategories(
          accessToken.accessToken,
          userToken ?? null
        );
        response.data = (response.data || []).filter(category => {
          if (
            !(
              category.short_name === 'courier-freight' ||
              category.short_name === 'commuting' ||
              category.short_name === 'social_value'
            )
          )
            return true;
          if (
            category.short_name === 'commuting' &&
            reportingPeriod?.report.commuting_included
          )
            return true;
          if (
            category.short_name === 'courier-freight' &&
            reportingPeriod?.report.freight_included
          )
            return true;
          if (
            category.short_name === 'social_value' &&
            reportingPeriod?.report.social_value_included
          )
            return true;
          return false;
        });
        return response.data;
      }
      return { categories: null };
    } catch (error) { }
  }
);

const initialState = { categories: null, isPending: false };

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,

  extraReducers: {
    [getAllCategories.pending]: (state, action) => {
      state.isPending = true;
    },
    [getAllCategories.fulfilled]: (state, action) => {
      const actionColumn = {
        isRequired: true,
        isRestricted: true,
        key: 'action_buttons',
        name: 'action',
        options_mode: 'd',
        type: 'action',
        width: 10,
      };
      state.categories = action.payload;
      state.categories.map(category =>
        // category.columns.map(data => console.log(data));
        category.columns.push(actionColumn)
      );
      state.isPending = false;
    },
    [getAllCategories.rejected]: (state, action) => {
      state.categories = null;
      state.isPending = false;
    },
  },
});

const { reducer } = categoriesSlice;

export default reducer;
