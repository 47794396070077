import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import companyService from '../services/company.services';

export const getReportsList = createAsyncThunk(
  'reports/getReports',
  async ({ accessToken }, thunkAPI) => {
    const s = thunkAPI.getState()
    const adminSelectedCompany = s?.adminCompany?.adminCompanyID;
    const userCompany = s?.user?.accountInfo?.payload?.company_id;
    const company = s?.company;

    try {
      if (!!userCompany || !!adminSelectedCompany) {
        const data = await companyService.getReportsOfCompany(
          accessToken?.accessToken,
          company?._id
        );
        // console.log(data.data);
        return data.data;
      }
      return { reports: null };
    } catch (err) {}
  }
);

const initialState = { reports: null };

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearReportData: () => {
      return { reports: null };
    },
  },
  extraReducers: {
    [getReportsList.fulfilled]: (state, action) => {
      state.reports = action.payload.reports;
    },
    // [getReportsList.rejected]: (state, action) => {
    //   state.reports = null;
    // },
  },
});

const { reducer, actions } = reportsSlice;

export const { clearReportData } = actions;
export default reducer;
