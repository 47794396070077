import axios from 'axios';

const API_URL = process.env.REACT_APP_WEB_API;

const getAllCompanies = (accessToken, adminToken) => {
  return axios.get(API_URL + '/api/company/all', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
    },
  });
};

const getCompany = (accessToken, company) => {
  return axios.get(API_URL + '/api/company/' + company, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
  });
};

const addCompany = async (accessToken, adminToken, payload) => {
  return await axios
    .post(
      API_URL + '/api/company',
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-api-key': adminToken,
          'Content-Type': 'application/json',
        },
      }
    )
  // .then(async () => {
  //   return await getAllCompanies(accessToken, adminToken);
  // });
};
const updateCompany = async (accessToken, adminToken, payload, company_id) => {
  return await axios
    .put(
      API_URL + '/api/company/' + company_id,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-api-key': adminToken,
          'Content-Type': 'application/json',
        },
      }
    )
};

const getReportsOfCompany = async (accessToken, companyId) => {
  return await axios.get(API_URL + '/api/company/reports/' + companyId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const addNewReport = async (accessToken, adminToken, payload) => {
  return await axios.post(API_URL + '/api/company/report', payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
      'Content-Type': 'application/json',
    },
  });
};

const updateReport = async (accessToken, adminToken, resource_id, payload) => {
  return await axios.put(API_URL + '/api/company/report/' + resource_id, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
      'Content-Type': 'application/json',
    },
  });
};

const updateThreshold = async (accessToken, adminToken, companyId, payload) => {
  return await axios.put(API_URL + '/api/company/threshold/' + companyId, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
      'Content-Type': 'application/json',
    },
  });
};

const getThreshold = async (accessToken, adminToken, companyId) => {
  return await axios.get(API_URL + '/api/company/threshold/' + companyId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': adminToken,
    },
  });
};

const companyService = {
  getAllCompanies,
  addCompany,
  updateCompany,
  getReportsOfCompany,
  updateReport,
  addNewReport,
  getCompany,
  updateThreshold,
  getThreshold
};

export default companyService;
