import React, { Suspense, lazy } from 'react';
import { Center, ChakraProvider, Spinner } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme';
import Fonts from './fonts';

const PublicRoutes = lazy(() => import('./routes/PublicRoutes'));
const PrivateRoutes = lazy(() => import('./routes/PrivateRoutes'));

const App = ({ instance, isPublicPath }) => {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <BrowserRouter>
        <Suspense fallback={
          <Center w="full" h="100vh">
            <Spinner id="spinner" size="xl" />
          </Center>
        }>
          {isPublicPath && <PublicRoutes />}
          {!isPublicPath && <PrivateRoutes instance={instance} />}
        </Suspense>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
