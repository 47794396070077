import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import siteInfoService from '../services/siteInfo.services';

export const getSitesListOfReport = createAsyncThunk(
  'siteInfo/getSitesOfReport',
  async ({ accessToken, report, analysisType }, thunkAPI) => {
    let adminSelectedCompany = thunkAPI.getState().adminCompany.adminCompanyID;
    let reportingPeriod = thunkAPI.getState().reportingPeriod;
    let report_id = report ? report : reportingPeriod.id;


    // console.log(reportingPeriod);

    try {
      if (!!reportingPeriod || !!adminSelectedCompany) {
        const data = await siteInfoService.getSitesListOfReport(
          accessToken.accessToken,
          report_id,
          analysisType
        );
        return data.data;
      }
      return { siteInfo: null };
    } catch (error) {}
  }
);

const initialState = { siteInfo: null };

const siteInfoSlice = createSlice({
  name: 'siteInfo',
  initialState,
  reducers: {
    clearSiteInfoData: () => {
      return { siteInfo: null };
    },
  },
  extraReducers: {
    [getSitesListOfReport.fulfilled]: (state, action) => {
      //   console.log(action.payload[0]);
      state.siteInfo = action.payload;
    },
    [getSitesListOfReport.rejected]: (state, action) => {
      state.siteInfo = null;
    },
  },
});

const { reducer, actions } = siteInfoSlice;

export const { clearSiteInfoData } = actions;
export default reducer;
