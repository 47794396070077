import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import sitesService from '../services/sites.services';

export const getSitesList = createAsyncThunk(
  'sites/getSites',
  async ({ accessToken, analysisType }, thunkAPI) => {
    const s = thunkAPI.getState();
    const adminSelectedCompany = s?.adminCompany?.adminCompanyID;
    const userCompany = s?.user?.accountInfo?.payload?.company_id;
    const company = s?.company;

    try {
      if (!!userCompany || !!adminSelectedCompany) {
        const data = await sitesService.getSitesList(
          accessToken?.accessToken,
          company?._id,
          analysisType
        );
        return data.data;
      }
      return { sites: null };
    } catch (error) {}
  }
);

const initialState = { sites: null };

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    clearSiteData: () => {
      return { sites: null };
    },
  },
  extraReducers: {
    [getSitesList.fulfilled]: (state, action) => {
      state.sites = action.payload.sites;
    },
    [getSitesList.rejected]: (state, action) => {
      state.sites = null;
    },
  },
});

const { reducer, actions } = sitesSlice;

export const { clearSiteData } = actions;
export default reducer;
