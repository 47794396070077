import axios from 'axios';

const API_URL = process.env.REACT_APP_WEB_API;

const reportingPeriod = (
  id,
  startDate,
  endDate,
  report_summary,
  report,
  emissions
) => {
  if (!!id && !!startDate && !!endDate) {
    localStorage.setItem(
      'report',
      JSON.stringify({
        id: id,
        startDate: startDate,
        endDate: endDate,
        report_summary,
        report,
        emissions,
      })
    );
  }
};

const removeReportingPeriod = () => {
  localStorage.removeItem('report');
};

const getReportsSummary = async (accessToken, reportId) => {
  return await axios.get(API_URL + '/api/report/' + reportId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const submitReport = async (accessToken, reportId) => {
  return await axios.post(
    API_URL + '/api/report/submit/' + reportId,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};
const requestResubmitReport = async (accessToken, reportId, payload) => {
  return await axios.post(
    API_URL + '/api/report/resubmit/' + reportId,
    payload,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

const reportService = {
  reportingPeriod,
  removeReportingPeriod,
  getReportsSummary,
  submitReport,
  requestResubmitReport
};

export default reportService;
