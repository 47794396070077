import { createSlice } from '@reduxjs/toolkit';

const initialState = { choose_option: null, estimate_inputs: {}, categories: [], budgetThreshold: null };

const estimatesSlice = createSlice({
    name: 'estimates',
    initialState,
    reducers: {
        setChooseOptions: (state, action) => {
            return { ...state, choose_option: action.payload.choose_option }
        },
        setCategories: (state, action) => {
            return { ...state, categories: action.payload.categories }
        },
        setEstimateInputs: (state, action) => {
            return { ...state, estimate_inputs: { ...state.estimate_inputs, ...action.payload.estimate_inputs } }
        },
        setEstimateInputUpdate: (state, action) => {
            let estimate = state.estimate_inputs[action.payload.id];
            if (estimate) {
                estimate = { ...estimate, ...action.payload.update_obj }
                return { ...state, estimate_inputs: { ...state.estimate_inputs, [action.payload.id]: estimate } }
            }

        },
        deleteEstimatesInput: (state, action) => {
            let estimates = { ...state.estimate_inputs }
            if (estimates[action.payload.id]) {
                delete estimates[action.payload.id]
                return { ...state, estimate_inputs: { ...estimates } }
            }
        },
        clearEstimates: () => {
            return { choose_option: null, estimate_inputs: {}, categories: [], budgetThreshold: null };
        },
        setBudgetThreshold: (state, action) => {
            return { ...state, budgetThreshold: action.payload }
        },
    }
});

const { reducer, actions } = estimatesSlice;

export const { setChooseOptions, setEstimateInputs, clearEstimates, setEstimateInputUpdate, setCategories, deleteEstimatesInput, setBudgetThreshold } = actions;

export default reducer;