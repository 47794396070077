import { extendTheme } from "@chakra-ui/react"

export const theme = extendTheme({
    colors: {
        planetBlue: '#0C70E5',
        forestGreen: '#193133',
        sagaGreen: '#707052',
        sunsetYellow: '#F99A1C',
        smokeGrey: {
            100: '#B9AAA7',
            60: '#d5ccca',
            40: '#e3dddc',
            20: '#f1eeed',
            15: '#f8f7f6',
            10: '#fcfbfb',
        },
        leafGreen: '#B2D3B4',
        blossomPink: '#FFB2B2',
        skyBlue: '#86C3F2',
        white: '#FFFFFF',
        black: '#000000',
        thinBlue: '#f3f9fe',
    },
    fonts: {
        heading: `'Tobias Extra Bold', sans-serif`,
        body: `'Beausite Classic Clear', sans-serif`,
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: 'full',
                borderWidth: 1,
                borderColor: 'forestGreen',
                textColor: 'forestGreen',
                bgColor: 'transparent',
                bg: 'transparent',
                background: 'transparent',
                fontWeight: 'normal',
                _hover: {
                    borderColor: 'planetBlue',
                    textColor: 'white',
                    bgColor: 'planetBlue',
                    bg: 'planetBlue',
                    background: 'planetBlue',
                    _disabled: {
                        borderColor: 'forestGreen',
                        textColor: 'forestGreen',
                        bgColor: 'transparent',
                        bg: 'transparent',
                        background: 'transparent',
                    }
                }
            }
        },
        Select: {
            baseStyle: {
                field: {
                    borderWidth: 1,
                    borderColor: "forestGreen",
                    color: "forestGreen",
                    _hover: {
                        borderColor: "forestGreen",
                    },
                    _focusVisible: {
                        outline: 'forestGreen'
                    }
                }
            }
        },
        Heading: {
            baseStyle: {
                color: "forestGreen"
            }
        },
        MenuItem: {
            baseStyle: {
                _hover: {
                    bg: 'planetBlue',
                    color: 'white'
                },
                _focus: {
                    bg: 'planetBlue',
                    color: 'white'
                }
            }
        },
        Input: {
            baseStyle: {
                field: {
                    borderWidth: 1,
                    borderColor: "forestGreen",
                    color: "forestGreen",
                    _hover: {
                        borderColor: "forestGreen",
                    },
                    _focusVisible: {
                        outline: 'forestGreen'
                    }
                }
            }
        },
        Textarea: {
            baseStyle: {
                borderWidth: 1,
                borderColor: "forestGreen",
                color: "forestGreen",
                _hover: {
                    borderColor: "forestGreen",
                },
                _focusVisible: {
                    outline: 'forestGreen'
                }
            }
        },
        FormLabel: {
            baseStyle: {
                color: "forestGreen",
            }
        },
        Alert: {
            defaultProps: {
                variant: 'custom',
            },
            baseStyle: {
                container: {
                    color: "black",
                    bg: "skyBlue"
                }
            }
        },
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        background: "planetBlue",
                    }
                }
            }
        }
    }
})