import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const publicPaths = ["/supplier-upload", "/invite_user", "/successfully-registered"]
const isPublicPath = publicPaths.some(path => window.location.pathname.startsWith(path))

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
let msalInstance;
if (!isPublicPath) msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <StrictMode>
    <Provider store={store}>
      <ColorModeScript />
      <App instance={msalInstance} isPublicPath={isPublicPath} />
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
