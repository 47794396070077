import { createSlice } from '@reduxjs/toolkit';
import { FaAcquisitionsIncorporated } from 'react-icons/fa';

const initialState = { data: null, editData: null, updatedEditData: null, idMap: null, isEditing: null, categoryIdMap:{} };

const emissionsSlice = createSlice({
    name: 'emissions',
    initialState,
    reducers: {
        setTableData: (state, action) => {
            const data = { nodes: action.payload.data }
            return { ...state, data: data }
        },
        setIdMapStore: (state, action) => {
            const tmp_id_map = { ...action.payload.data }
            return { ...state, idMap: tmp_id_map }
        },
        clearAndsetIdMapStore: (state, action) => {
            return { ...state, idMap: {...(action.payload.data)} }
        }, 
        setCategoryWiseIdMap: (state, action) => {
            return { ...state, categoryIdMap: {...action.payload.categoryIdMap }}
        },
        updateCategoryWiseIdMap: (state, action) => {
            let tmp_category_map = {...state.categoryIdMap};
            if(!(action.payload.category in tmp_category_map)) {
                tmp_category_map[action.payload.category] = {}
            }
            tmp_category_map[action.payload.category] = {...tmp_category_map[action.payload.category], ...(action.payload.data)}
            return { ...state, categoryIdMap: {...tmp_category_map }}
        },
        deleteCategoryWiseIdMap: (state, action) => {
            let tmp_category_map = {...state.categoryIdMap};
            if((action.payload.category in tmp_category_map)) {
                tmp_category_map[action.payload.category] ={...action.payload.data}
            }
            return { ...state, categoryIdMap: {...tmp_category_map }}
        },
        setEditData: (state, action) => {
            return { ...state, editData: action.payload.data }
        },
        setUpdatedEditData: (state, action) => {
            return { ...state, updatedEditData: action.payload.data }
        },
        setEditingEmission: (state, action) => {
            return { ...state, isEditing: action.payload.data }
        },
        unSetEditingEmission: (state, action) => {
            return { ...state, isEditing: null }
        },
        clearEmissions: () => {
            return { data: null, editData: null, updatedEditData: null, idMap: null, isEditing: null, categoryIdMap:{} };
        }
    }
});

const { reducer, actions } = emissionsSlice;

export const { setTableData, setIdMapStore, clearAndsetIdMapStore, setEditData, setUpdatedEditData, clearEmissions, setEditingEmission, unSetEditingEmission, setCategoryWiseIdMap, updateCategoryWiseIdMap, deleteCategoryWiseIdMap } = actions;

export default reducer;