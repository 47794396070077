import axios from 'axios';
import { getAdditionalPath } from '.';

const API_URL = process.env.REACT_APP_WEB_API;

const getSitesListOfReport = (accessToken, reportId, analysisType) => {
  return axios.request({
    method: 'GET',
    url: `${API_URL}/api${getAdditionalPath(analysisType)}/siteinfo/sites/${reportId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getSiteInfo = async (accessToken, reportId, analysisType) => {
  return await axios.request({
    method: 'GET',
    url: `${API_URL}/api${getAdditionalPath(analysisType)}/siteinfo/${reportId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const updateCompanyWideSite = async (accessToken, reportId, payload) => {
  return await axios.put(API_URL + '/api/siteinfo/companywide/' + reportId, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const addNewSiteInfo = async (accessToken, payload) => {
  return await axios.post(API_URL + '/api/siteinfo', payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const updateSiteInfo = async (accessToken, resource_id, payload) => {
  return await axios.put(API_URL + '/api/siteinfo/' + resource_id, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const updateEmployeeDetails = async (accessToken, siteId, payload) => {
  return await axios.post(API_URL + '/api/v2/siteinfo/' + siteId + '/', payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const deleteSiteInfo = async (accessToken, siteInfoId, analysisType) => {
  try {
    return await axios
      .request({
        method: 'DELETE',
        url: `${API_URL}/api${getAdditionalPath(analysisType)}/siteinfo/${siteInfoId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  } catch (e) {
    return e.response;
  }
};

const importSites = async (accessToken, reportingPeriodId, companyId, data, analysisType) => {
  return await axios.request({
    method: 'POST',
    url: `${API_URL}/api${getAdditionalPath(analysisType)}/v2/site/import/${companyId}/${reportingPeriodId}`,
    data: data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data'
    },
  });
};

const siteInfoService = {
  getSitesListOfReport,
  getSiteInfo,
  updateCompanyWideSite,
  addNewSiteInfo,
  updateSiteInfo,
  updateEmployeeDetails,
  deleteSiteInfo,
  importSites
};

export default siteInfoService;
