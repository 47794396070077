import axios from 'axios';

const API_URL = process.env.REACT_APP_WEB_API;

const getUserDetails = (accessToken, userId) => {
  const ds = axios.request({
    method: 'GET',
    url: `${API_URL}/api/auth/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return ds;
};

const userService = {
  getUserDetails,
};

export default userService;
