import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../services/user.services';

export const getUserDetails = createAsyncThunk(
  'user/userDetails',
  async ({ accessToken, userAccount }, thunkAPI) => {
    try {
      const data = await userService.getUserDetails(
        accessToken.accessToken,
        userAccount.idTokenClaims.sub || userAccount.idTokenClaims.oid
      );
      let response = { account: userAccount, accountInfo: data.data };
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || null);
     }
  }
);

const initialState = { account: null, accountInfo: null, error: null };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirstTimeLogin: (state, action) => {
      let accountInfo = {}
      
      if (state.accountInfo) accountInfo = { ...state.accountInfo }
      if (state.accountInfo?.payload) accountInfo.payload = { ...state.accountInfo.payload }
      else accountInfo.payload = {}
      
      accountInfo.payload.ifFirstTimeLogin = action?.payload;
      
      state.accountInfo = accountInfo;
      state.error = null;
    }
  },
  extraReducers: {
    [getUserDetails.fulfilled]: (state, action) => {
      state.account = action?.payload?.account;
      state.accountInfo = action?.payload?.accountInfo;
      state.error = null;
    },
    [getUserDetails.rejected]: (state, action) => {
      state.sites = null;
      state.error = action?.payload || null;
    },
  },
});

const { reducer, actions } = userSlice;
export const { setFirstTimeLogin } = actions;
export default reducer;
