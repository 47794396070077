import { createSlice } from '@reduxjs/toolkit';

const company = JSON.parse(localStorage.getItem('company'));

const initialState = {
    _id: company?._id,
    name: company?.name,
    dynamics_id: company?.dynamics_id,
    scope3_included: company?.scope3_included,
    scope3_spend_data_included: company?.scope3_spend_data_included,
    scope3_activity_data_included: company?.scope3_activity_data_included,
    biscert_included: company?.biscert_included,
    estimates_included: company?.estimates_included
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompany: (state, action) => {
            localStorage.setItem(
                'company',
                JSON.stringify({
                    _id: action.payload._id,
                    name: action.payload.name,
                    dynamics_id: action.payload.dynamics_id,
                    scope3_included: action.payload.scope3_included,
                    scope3_spend_data_included: action.payload.scope3_spend_data_included || false,
                    scope3_activity_data_included: action.payload.scope3_activity_data_included || false,
                    biscert_included: action.payload.biscert_included,
                    estimates_included: action.payload.estimates_included,
                })
            );
            return JSON.parse(localStorage.getItem('company'));
        },
        clearCompany: () => {
            localStorage.removeItem('company');
            return {
                _id: null,
                name: null,
                dynamics_id: null,
                scope3_included: false,
                scope3_spend_data_included: false,
                scope3_activity_data_included: false,
                estimates_included: false,
                biscert_included: false
            };
        },
    },
});

const { reducer, actions } = companySlice;

export const { setCompany, clearCompany } = actions;
export default reducer;