import { createSlice } from '@reduxjs/toolkit';

const adminCompany = JSON.parse(localStorage.getItem('adminCompany'));

const initialState = {
  adminCompanyID: adminCompany?.adminCompanyID ?? null,
  companyName: adminCompany?.companyName ?? null,
  loggedIn: adminCompany?.loggedIn ?? false,
};

const adminCompanySlice = createSlice({
  name: 'adminCompany',
  initialState,
  reducers: {
    setAdminCompany: (state, action) => {
      localStorage.setItem(
        'adminCompany',
        JSON.stringify({
          adminCompanyID: action.payload.companyId,
          companyName: action.payload.companyName,
          loggedIn: true,
        })
      );
      return JSON.parse(localStorage.getItem('adminCompany'));
    },
    clearAdminCompany: () => {
      localStorage.removeItem('adminCompany');
      return { adminCompany: null, companyName: null, loggedIn: false };
    },
  },
});

const { reducer, actions } = adminCompanySlice;

export const { setAdminCompany, clearAdminCompany } = actions;
export default reducer;
